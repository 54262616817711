import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import { AlertReducer, AlertsAppState } from "./alerts/alertModel"
import { ConsoleAppState, ConsoleReducer } from "./console/consoleModel"
import {
  DocumentEventsMiddleware,
  DocumentEventsReducer,
  DocumentEventsState,
} from "./documentEvents/documentEventsModel"
import { ModalAppState, ModalReducer } from "./modals/modals"
import {
  NodeConnectionsReducer,
  NodeConnectionsState,
} from "./nodeConnections/nodeConnectionsModel"
import { api } from "./services/irohApi"
import { SlideOverAppState, SlideOverReducer } from "./slideOver/slideOvers"
import {
  WebSocketMiddleware,
  WebsocketAppState,
  WebsocketReducer,
} from "./websocket/websocket"

export interface AppState {
  [api.reducerPath]: ReturnType<typeof api.reducer>
  console: ConsoleAppState
  modal: ModalAppState
  slideOver: SlideOverAppState
  websocket: WebsocketAppState
  documentEvents: DocumentEventsState
  nodeConnections: NodeConnectionsState
  alerts: AlertsAppState
}

// const logger = (_ : any) => (next: any) => (action: any) => {
//   console.log('dispatching', action)
//   // let result = next(action)
//   // console.log('next state', store.getState())
//   return next(action)
// }

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
    console: ConsoleReducer,
    modal: ModalReducer,
    slideOver: SlideOverReducer,
    websocket: WebsocketReducer,
    documentEvents: DocumentEventsReducer,
    nodeConnections: NodeConnectionsReducer,
    alerts: AlertReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(WebSocketMiddleware)
      .concat(DocumentEventsMiddleware)
      .concat(api.middleware),
  // .concat(logger),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
