import React from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

import NotFound from "./layouts/NotFound"

const router = createBrowserRouter([
  {
    path: "/",
    lazy: () => import("./marketing/MarketingLayout"),
    children: [
      {
        path: "",
        lazy: () => import("./marketing/MarketingHome"),
      },
      // {
      //   path: '/use-cases',
      //   lazy: () => import('./marketing/UseCases'),
      // },
      {
        path: "/pricing",
        lazy: () => import("./marketing/Pricing"),
      },
    ],
  },
  {
    path: "/pricing",
    lazy: () => import("./marketing/Pricing"),
  },
  {
    path: "/login",
    lazy: () => import("./session/Login"),
  },
  {
    path: "/login/forgot",
    lazy: () => import("./session/Forgot"),
  },
  {
    path: "/login/reset/:token",
    lazy: () => import("./session/ResetPassword"),
  },
  {
    path: "/verify/:token",
    lazy: () => import("./session/VerifyEmail"),
  },
  {
    path: "/signup",
    lazy: () => import("./session/Signup"),
  },
  {
    path: "/:username/welcome",
    lazy: () => import("./onboarding/Welcome"),
  },
  {
    path: "/",
    lazy: () => import("./layouts/AppLayout"),
    children: [
      {
        path: "home",
        lazy: () => import("./home/Home"),
      },
      {
        path: "settings",
        lazy: () => import("./accountSettings/AccountSettingsLayout"),
        children: [
          {
            path: "",
            lazy: () => import("./accountSettings/AccountSettings"),
          },
          {
            path: "billing",
            lazy: () => import("./billing/Billing"),
          },
          {
            path: "api-keys",
            lazy: () => import("./apiKeys/ApiKeys"),
          },
        ],
      },
      {
        path: ":username",
        lazy: () => import("./profile/UserOrTeamProfile"),
        children: [
          {
            path: "",
            lazy: () => import("./teams/TeamProjects"),
          },
          {
            path: "members",
            lazy: () => import("./teams/TeamMembers"),
          },
        ],
      },
      {
        path: ":username/new/project",
        lazy: () => import("./project/NewProject"),
      },
      {
        path: ":username/new/team",
        lazy: () => import("./teams/NewTeam"),
      },
    ],
  },
  {
    path: "/:username",
    lazy: () => import("./project/ProjectLayout"),
    children: [
      {
        path: ":project",
        lazy: () => import("./project/ProjectHome"),
      },
      {
        path: ":project/settings",
        lazy: () => import("./project/ProjectSettings"),
      },
      {
        path: ":project/blobs",
        lazy: () => import("./blobs/ContentLayout"),
        children: [
          {
            path: "",
            lazy: () => import("./blobs/BlobBrowser"),
          },
          {
            path: "collections",
            lazy: () => import("./blobs/CollectionBrowser"),
          },
          {
            path: "documents",
            lazy: () => import("./docs/DocumentsList"),
          },
          {
            path: "upload",
            lazy: () => import("./blobs/UploadContent"),
          },
        ],
      },
      {
        path: ":project/documents",
        lazy: () => import("./console/ChooseDocument"),
      },
      {
        path: ":project/documents/:doc_id",
        lazy: () => import("./console/Console"),
      },
      {
        path: ":project/settings",
        lazy: () => import("./project/ProjectSettings"),
      },
    ],
  },
  {
    path: "legal",
    lazy: () => import("./legal/LegalLayout"),
    children: [
      {
        path: "privacy",
        lazy: () => import("./legal/PrivacyPolicy"),
      },
      {
        path: "terms",
        lazy: () => import("./legal/TermsOfService"),
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
])

export default function Router() {
  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  )
}
