import { AnyAction, Dispatch, MiddlewareAPI, Reducer } from "redux"

import { api } from "../services/irohApi"
import { ServerToClientMessageType, WebSocketAction } from "../services/wsApi"

export const DocumentEventsMiddleware = (store: MiddlewareAPI) => {
  return (next: Dispatch) => (action: WebSocketAction) => {
    switch (action.type) {
      case ServerToClientMessageType.DOCUMENT_INSERT_LOCAL:
        // patch the document with the new entry
        store.dispatch(
          api.util.updateQueryData(
            "docGet",
            {
              username: action.payload.username,
              project: action.payload.project,
              doc_id: action.payload.doc_id,
            },
            (doc) => {
              // update the local copy of the document
              const idx = doc.data.findIndex(
                (item) => item.key === action.payload.entry.key,
              )
              if (idx >= 0) {
                doc.data[idx] = action.payload.entry
              } else {
                doc.data.push(action.payload.entry)
              }
            },
          ) as unknown as AnyAction, // this is b/c the store is thunk aware, but the type def passed to this middleware is not.
        )
        console.log("insert local", action.payload)
        break
      case ServerToClientMessageType.DOCUMENT_INSERT_REMOTE:
        // patch the document with the new entry
        store.dispatch(
          api.util.updateQueryData(
            "docGet",
            {
              username: action.payload.username,
              project: action.payload.project,
              doc_id: action.payload.doc_id,
            },
            (doc) => {
              // update the local copy of the document
              const idx = doc.data.findIndex(
                (item) => item.key === action.payload.entry.key,
              )
              if (idx >= 0) {
                doc.data[idx] = action.payload.entry
              } else {
                doc.data.push(action.payload.entry)
              }
            },
          ) as unknown as AnyAction, // this is b/c the store is thunk aware, but the type def passed to this middleware is not.
        )
        // console.log('insert remote', action.payload);
        break
      case ServerToClientMessageType.DOCUMENT_CONTENT_READY:
        // TODO - patch with content availability
        break
      case ServerToClientMessageType.DOCUMENT_SYNC_FINISHED:
        // Note: don't patch here! This is just a notification that the sync is finished.
        break
    }

    return next(action)
  }
}

export interface DocumentEventsState {
  [doc_id: string]: DocumentEvent[]
}

const initialState: DocumentEventsState = {}

interface DocumentEvent {
  type: ServerToClientMessageType
  peer?: string

  key?: string
  hash?: string
  contentLength?: number
  timestamp?: number
  content_status?: string
}

const MAX_EVENTS = 200

export const DocumentEventsReducer: Reducer<DocumentEventsState, AnyAction> = (
  state = initialState,
  action,
): DocumentEventsState => {
  let events
  switch (action.type) {
    case ServerToClientMessageType.DOCUMENT_INSERT_LOCAL:
      events = [
        ...(state[action.payload.doc_id] || []),
        {
          type: ServerToClientMessageType.DOCUMENT_INSERT_LOCAL,
          key: action.payload.entry.key,
          hash: action.payload.entry.hash,
          timestamp: action.payload.entry.timestamp,
          contentLength: action.payload.entry.contentLength,
        },
      ]
      events.splice(MAX_EVENTS - 1)
      return { ...state, [action.payload.doc_id]: events }
    case ServerToClientMessageType.DOCUMENT_INSERT_REMOTE:
      events = [
        ...(state[action.payload.doc_id] || []),
        {
          type: ServerToClientMessageType.DOCUMENT_INSERT_REMOTE,
          peer: action.payload.peer,
          key: action.payload.entry.key,
          hash: action.payload.entry.hash,
          timestamp: action.payload.entry.timestamp,
          contentLength: action.payload.entry.contentLength,
        },
      ]
      events.splice(MAX_EVENTS - 1)
      return { ...state, [action.payload.doc_id]: events }
    case ServerToClientMessageType.DOCUMENT_CONTENT_READY:
      events = [
        ...(state[action.payload.doc_id] || []),
        {
          type: ServerToClientMessageType.DOCUMENT_CONTENT_READY,
          hash: action.payload.hash,
        },
      ]
      events.splice(MAX_EVENTS - 1)
      return { ...state, [action.payload.doc_id]: events }
    case ServerToClientMessageType.DOCUMENT_SYNC_FINISHED:
      events = [
        ...(state[action.payload.doc_id] || []),
        {
          type: ServerToClientMessageType.DOCUMENT_SYNC_FINISHED,
          peer: action.payload.peer,
        },
      ]
      events.splice(MAX_EVENTS - 1)
      return { ...state, [action.payload.doc_id]: events }
    case ServerToClientMessageType.DOCUMENT_NEIGHBOR_UP:
      events = [
        ...(state[action.payload.doc_id] || []),
        {
          type: ServerToClientMessageType.DOCUMENT_NEIGHBOR_UP,
          peer: action.payload.peer,
        },
      ]
      events.splice(MAX_EVENTS - 1)
      return { ...state, [action.payload.doc_id]: events }
    case ServerToClientMessageType.DOCUMENT_NEIGHBOR_DOWN:
      events = [
        ...(state[action.payload.doc_id] || []),
        {
          type: ServerToClientMessageType.DOCUMENT_NEIGHBOR_DOWN,
          peer: action.payload.peer,
        },
      ]
      events.splice(MAX_EVENTS - 1)
      return { ...state, [action.payload.doc_id]: events }
    default:
      return state
  }
}
