import { Dispatch, MiddlewareAPI, Reducer } from "redux"
import {
  Subscriber,
  WEBSOCKET_CONNECT,
  WEBSOCKET_CONNECTED,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_DISCONNECTED,
  WEBSOCKET_SEND,
  WebSocketAction,
  websocketConnected,
  websocketDisconnected,
} from "../services/wsApi"

export const WebSocketMiddleware = (store: MiddlewareAPI) => {
  let socket: WebSocket | null = null
  return (next: Dispatch) => (action: WebSocketAction) => {
    // intercept & send messages to the server
    if (action[WEBSOCKET_SEND]) {
      const msg = JSON.stringify(action[WEBSOCKET_SEND])
      if (socket !== null && socket.readyState === WebSocket.OPEN) {
        socket.send(msg)
      } else {
        console.warn("websocket not connected. dropping message:", msg)
      }
      return
    }

    switch (action.type) {
      case WEBSOCKET_CONNECT:
        if (socket !== null) {
          socket.close()
        }
        socket = new WebSocket(action.payload.url)

        socket.onopen = () => {
          store.dispatch(websocketConnected())
        }

        socket.onmessage = (event) => {
          try {
            const action = JSON.parse(event.data)
            // TODO - catch malformed actions from the server
            store.dispatch(action)
          } catch (e) {
            console.error("parsing server websocket message:", e)
          }
        }

        socket.onclose = () => {
          store.dispatch(websocketDisconnected())
        }

        break

      case WEBSOCKET_DISCONNECT:
        if (socket !== null) {
          socket.close()
          socket = null
        }
        break

      default:
        break
    }

    return next(action)
  }
}

export enum ConnectionState {
  Disconnected = 0,
  Connecting = 1,
  Connected = 2,
  Failed = 3,
}

export interface WebsocketAppState {
  status: ConnectionState
  lastConnectAttempt: Date | null
  subscribers: Subscriber[]
}

const initialState: WebsocketAppState = {
  status: ConnectionState.Disconnected,
  lastConnectAttempt: null,
  subscribers: [],
}

export const WebsocketReducer: Reducer<WebsocketAppState, WebSocketAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case WEBSOCKET_CONNECT:
      return {
        ...state,
        status: ConnectionState.Connecting,
        lastConnectAttempt: new Date(),
      }
    case WEBSOCKET_CONNECTED:
      return {
        ...state,
        status: ConnectionState.Connected,
      }
    case WEBSOCKET_DISCONNECTED:
      return {
        ...state,
        status: ConnectionState.Disconnected,
      }
    default:
      return state
  }
}
