import * as Sentry from "@sentry/react"
import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./store"

import Router from "./Router"
import "./css/index.css"
import ErrorPage from "./layouts/ErrorPage"
import reportWebVitals from "./reportWebVitals"

const MODE = import.meta.env.VITE_APP_MODE || "production"

if (MODE === "production") {
  Sentry.init({
    dsn: "https://9419e7414717a36805218769a17540a3@o4506100253065216.ingest.sentry.io/4506106766426112",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /iroh\.network\//],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of the transactions
    // Set release version
    environment: process.env.NODE_ENV,
  })
}

const root = createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <Provider store={store}>
        <Router />
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
