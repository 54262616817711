import React from "react"

import Button from "../chrome/Button"

export default function NotFound() {
  return (
    <div className="bg-white antialiased dark:bg-zinc-900">
      <div className="flex h-screen w-full items-center justify-center">
        <div className="text-center">
          <h2 className="mb-5 font-space-mono">Nothing to see here!</h2>
          <Button href="/">Go to the home page</Button>
        </div>
      </div>
    </div>
  )
}
