import { Reducer } from "redux"

export enum ModalType {
  NONE = "NONE",
  UPLOAD_FILES = "UPLOAD_FILES",
  JOIN_DOC = "JOIN_DOC",
  CREATE_DOCUMENT = "CREATE_DOCUMENT",
  DOC_SHARE = "DOC_SHARE",
  DOC_DROP = "DOC_DROP",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
  UPLOAD_BLOB = "UPLOAD_BLOB",
  PROJECT_DELETE = "PROJECT_DELETE",
  CREATE_API_KEY = "CREATE_API_KEY",
  DELETE_BLOBS = "DELETE_BLOBS",
  REVOKE_API_KEY = "REVOKE_API_KEY",
  TEAM_INVITE_CREATE = "TEAM_INVITE_CREATE",
  TEAM_MEMBER_REMOVE = "TEAM_MEMBER_REMOVE",
  BLOB_TICKET = "BLOB_TICKET",
}

export interface Message<T> {
  type: string
  payload?: T
}

export interface ModalAction extends Message<{ modal: ModalType; data: any }> {}

const SET_MODAL = "SET_MODAL"
const CLEAR_MODAL = "CLEAR_MODAL"

export const showModal = (modal: ModalType, data: any = ""): ModalAction => ({
  type: SET_MODAL,
  payload: { modal, data },
})

export const dismissModal = (): ModalAction => ({
  type: CLEAR_MODAL,
  payload: { modal: ModalType.NONE, data: "" },
})

export interface ModalAppState {
  modal: ModalType
  data: any
}

const initialState: ModalAppState = {
  modal: ModalType.NONE,
  data: "",
}

export const ModalReducer: Reducer<ModalAppState, ModalAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SET_MODAL:
      return {
        modal: action.payload?.modal || ModalType.NONE,
        data: action.payload?.data || "",
      }
    case CLEAR_MODAL:
      return {
        modal: ModalType.NONE,
        data: "",
      }
  }

  return state
}
