import { Reducer } from "redux"

export enum SlideOverType {
  NONE = 0,
  BILLING_PLAN_SELECT = 1,
}

export interface Message<T> {
  type: string
  payload?: T
}

export interface SlideOverAction extends Message<{ sheet: SlideOverType }> {}

const SET_SLIDE_OVER = "SET_SLIDE_OVER"
const DISMISS_SLIDE_OVER = "DISMISS_SLIDE_OVER"

export const setSlideOver = (sheet: SlideOverType): SlideOverAction => ({
  type: SET_SLIDE_OVER,
  payload: { sheet },
})

export const dismissSlideOver = (): SlideOverAction => ({
  type: DISMISS_SLIDE_OVER,
  payload: { sheet: SlideOverType.NONE },
})

export interface SlideOverAppState {
  sheet: SlideOverType
}

const initialState: SlideOverAppState = {
  sheet: SlideOverType.NONE,
}

export const SlideOverReducer: Reducer<SlideOverAppState, SlideOverAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SET_SLIDE_OVER:
      return {
        sheet: action.payload?.sheet || SlideOverType.NONE,
      }
    case DISMISS_SLIDE_OVER:
      return {
        sheet: SlideOverType.NONE,
      }
  }

  return state
}
