import { AnyAction, Reducer } from "redux"

import { ServerToClientMessageType } from "../services/wsApi"
import { LatencySample } from "../types"

export interface NodeConnectionsState {
  [node_id: string]: ConnectionInfo
}

const initialState: NodeConnectionsState = {}

interface ConnectionInfo {
  id: number
  peer?: string
  derp_region?: number
  conn_type: "direct" | "relay" | "mixed" | "disconnected"
  latency: number
  latencies: LatencySample[]
}

interface ServerConnectionInfo {
  id: number
  peer?: string
  derp_region?: number
  conn_type: { Direct?: any; Relay?: any }
  latency: number
}

export const NodeConnectionsReducer: Reducer<NodeConnectionsState, AnyAction> =
  (state = initialState, action): NodeConnectionsState => {
    switch (action.type) {
      case ServerToClientMessageType.NODE_CONNECTIONS:
        return action.payload.reduce(
          (acc: NodeConnectionsState, conn: ServerConnectionInfo) => {
            const peer = conn.peer
            if (peer) {
              if (conn.latency) {
                let latencies: LatencySample[] = [
                  { y: Math.round(conn.latency * 1000), x: new Date() },
                ]
                latencies = state[peer]
                  ? latencies.concat(state[peer].latencies)
                  : latencies
                // keep at most 5 minutes of latency samples
                latencies.splice(300 - 1)

                acc[peer] = {
                  id: conn.id,
                  derp_region: conn.derp_region,
                  latency: conn.latency,
                  latencies,
                  conn_type: connType(conn),
                }
              } else {
                acc[peer] = {
                  id: conn.id,
                  derp_region: conn.derp_region,
                  latency: -1,
                  latencies: state[peer] ? state[peer].latencies : [],
                  conn_type: "disconnected",
                }
              }
            }
            return acc
          },
          { ...state },
        )
      default:
        return state
    }
  }

function connType(conn: any) {
  const hasRelay = "Relay" in conn.conn_type
  const hasDirect = "Direct" in conn.conn_type
  if (hasRelay && hasDirect) {
    return "mixed"
  } else if (hasRelay) {
    return "relay"
  } else if (hasDirect) {
    return "direct"
  } else {
    return "disconnected"
  }
}
