import { Reducer } from "redux"

export interface Message<T> {
  type: string
  payload?: T
}

export enum AlertLevel {
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export interface Alert {
  id: string
  level: AlertLevel
  title: string
  description?: string
  options?: AlertOption[]
}

export interface AlertOption {
  label: string
  onClick: () => void
}

export interface AlertAction extends Message<{ alert: Alert }> {}
export interface AlertIdAction extends Message<{ alertId: string }> {}

const PUSH_ALERT = "PUSH_ALERT"
const DISMISS_ALERT = "DISMISS_ALERT"

export const pushSuccessAlert = (title: string): AlertAction =>
  pushAlert(AlertLevel.SUCCESS, title)
export const pushInfoAlert = (title: string): AlertAction =>
  pushAlert(AlertLevel.INFO, title)
export const pushWarnAlert = (title: string): AlertAction =>
  pushAlert(AlertLevel.WARN, title)
export const pushErrorAlert = (title: string): AlertAction =>
  pushAlert(AlertLevel.ERROR, title)

const pushAlert = (level: AlertLevel, title: string): AlertAction => ({
  type: PUSH_ALERT,
  payload: {
    alert: {
      id: alertId(),
      level,
      title,
    },
  },
})

export const pushAlertWithOptions = (
  level: AlertLevel,
  title: string,
  options: AlertOption[],
): AlertAction => ({
  type: PUSH_ALERT,
  payload: {
    alert: {
      id: alertId(),
      level,
      title,
      options,
    },
  },
})

export const dismissAlert = (alertId: string): AlertIdAction => ({
  type: DISMISS_ALERT,
  payload: { alertId },
})

export interface AlertsAppState {
  alerts: Alert[]
}

function alertId(): string {
  return Math.random().toString(36).substring(2, 9)
}

const initialState: AlertsAppState = {
  alerts: [],
}

export const AlertReducer: Reducer<
  AlertsAppState,
  AlertAction | AlertIdAction
> = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_ALERT:
      if (action.payload && "alert" in action.payload) {
        return {
          alerts: [...state.alerts, action.payload.alert],
        }
      }
      break
    case DISMISS_ALERT:
      if (typeof action.payload === "object" && "alertId" in action.payload) {
        const id = action.payload?.alertId
        return {
          alerts: state.alerts.filter((alert) => alert.id !== id),
        }
      }
      break
  }

  return state
}
