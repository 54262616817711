import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import React from "react"
import { Link } from "react-router-dom"

export default function ErrorPage() {
  return (
    <div className="w-full">
      <div className="container mx-auto flex flex-col pt-20 sm:w-1/2">
        <ExclamationCircleIcon className="mb-2 mr-auto h-16 w-16 text-gray-300" />
        <h1 className="text-2xl font-bold leading-loose text-gray-500">
          Something went wrong
        </h1>
        <p>
          Apologies, this looks like an error on our end. <br />
          Try reloading or go{" "}
          <Link to="/anchors" className="mt-6 font-bold text-indigo-500">
            Back to Networks
          </Link>
        </p>
      </div>
    </div>
  )
}
