import { IROH_ANCHOR_API_PREFIX } from "./irohApi"

export const ADD_MESSAGE = "ADD_MESSAGE"
export const WEBSOCKET_CONNECT = "WEBSOCKET_CONNECT"
export const WEBSOCKET_DISCONNECT = "WEBSOCKET_DISCONNECT"
export const WEBSOCKET_CONNECTED = "WEBSOCKET_CONNECTED"
export const WEBSOCKET_DISCONNECTED = "WEBSOCKET_DISCONNECTED"
export const WEBSOCKET_SUBSCRIBE = "WEBSOCKET_SUBSCRIBE"

export const WEBSOCKET_SEND = Symbol("WEBSOCKET_SEND")

// messages sent from server -> client via the websocket
export enum ServerToClientMessageType {
  IROH_PROVIDER_EVENT = "IrohProviderEvent",
  DOCUMENT_INSERT_LOCAL = "DocumentInsertLocal",
  DOCUMENT_INSERT_REMOTE = "DocumentInsertRemote",
  DOCUMENT_CONTENT_READY = "DocumentContentReady",
  DOCUMENT_SYNC_FINISHED = "DocumentSyncFinished",
  DOCUMENT_NEIGHBOR_UP = "DucmentNeighborUp",
  DOCUMENT_NEIGHBOR_DOWN = "DucmentNeighborDown",
  NODE_CONNECTIONS = "NodeConnections",
}

export interface Message<T> {
  type: string
  payload?: T
}

export interface Subscriber {
  (message: Message<any>): void
}

export interface WebSocketAction extends Message<any> {
  [WEBSOCKET_SEND]?: Message<any>
}

export const addMessage = (message: Message<any>) => ({
  type: ADD_MESSAGE,
  payload: message,
})

export interface WebSocketConnectAction extends Message<{ url: string }> {}

export const connectWebSocket = ({
  user,
  project,
}: { user: string; project: string }): WebSocketConnectAction => {
  const scheme = window.location.protocol === "https:" ? "wss:" : "ws:"
  const url = `${IROH_ANCHOR_API_PREFIX.replace(
    window.location.protocol,
    scheme,
  )}ws/${user}/${project}`
  return {
    type: WEBSOCKET_CONNECT,
    payload: { url },
  }
}

export interface WebSocketDisconnectAction extends Message<void> {}

export const disconnectWebSocket = (): WebSocketDisconnectAction => ({
  type: WEBSOCKET_DISCONNECT,
})

export const websocketConnected = () => ({
  type: WEBSOCKET_CONNECTED,
})

export const websocketDisconnected = () => ({
  type: WEBSOCKET_DISCONNECTED,
})

export const messageBuilder = (type: string, payload: any) =>
  `${type}::${JSON.stringify(payload)}`

export const subscribeToWebsocketEvents = (
  sub: Subscriber,
): WebSocketSendAction => ({
  type: WEBSOCKET_SUBSCRIBE,
  payload: sub,
})

export interface WebSocketSendAction extends Message<any> {
  [WEBSOCKET_SEND]?: Message<any>
}

// messages only sent from client to server
// All of these should have corresponding type definitions server side
const DOC_SUBSCRIBE = "DocSubscribe"
const DOC_UNSUBSCRIBE = "DocUnsubscribe"
const TEST_SUBSCRIBE = "TestSubscribe"
const TEST_HEARTBEAT = "TestHeartbeat"

// sendWebsocketmessage encapsulates the websocket message in a redux action.
// websocket middleware incercepts the [WEBSOCKET_SEND] symbol, decapsulates the message,
// and sends it to the server
// don't export this function, instead wrap it with a more specific function
const sendWebsocketMessage = (message: any): WebSocketSendAction => ({
  type: "SEND_WEBSOCKET_MESSAGE", // this is only here to conform to the Message interface
  [WEBSOCKET_SEND]: message,
})

// ask server to send updates about this document
export const docSubscribe = (
  user: string,
  docId: string,
): WebSocketSendAction =>
  sendWebsocketMessage({
    type: DOC_SUBSCRIBE,
    payload: {
      doc_id: docId,
      user: user,
    },
  })

// ask server to stop sending updates about this document
export const docUnsubscribe = (
  user: string,
  docId: string,
): WebSocketSendAction =>
  sendWebsocketMessage({
    type: DOC_UNSUBSCRIBE,
    payload: {
      doc_id: docId,
      user: user,
    },
  })

export const testSubscribe = (
  anchor_id: string,
  user_id: string,
  test_id: string,
): WebSocketSendAction =>
  sendWebsocketMessage({
    // TODO - refactor away "namespace" field
    namespace: "network",
    type: TEST_SUBSCRIBE,
    timestamp: 0,
    payload: {
      action: "join",
      anchor_id: anchor_id,
      test_id: test_id,
      user_id: user_id,
      data: "", // TODO - why is this here?
    },
  })

export const sendHeartbeatMessage = (
  anchor_id: string,
  user_id: string,
  test_id: string,
) =>
  sendWebsocketMessage({
    namespace: "network",
    type: TEST_HEARTBEAT,
    timestamp: 0,
    payload: {
      action: "heartbeat",
      anchor_id: anchor_id,
      test_id: test_id,
      user_id: user_id,
      data: "", // TODO - why is this here?
    },
  })
