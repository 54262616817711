import { Reducer } from "redux"

export enum Commands {
  DOC_SET = "DOC SET",
  DOC_DEL = "DOC DEL",
}

export const supportedCommands = Object.values(Commands)

export interface Message<T> {
  type: string
  payload?: T
}

export interface ConsoleAction extends Message<{ prefix: string }> {}

export const CONSOLE_SET_PREFIX = "console/set_prefix"

export const setPrefix = (prefix: string): ConsoleAction => ({
  type: CONSOLE_SET_PREFIX,
  payload: { prefix },
})

export interface ConsoleAppState {
  prefix: string
}

const initialState: ConsoleAppState = {
  prefix: "",
}

export const ConsoleReducer: Reducer<ConsoleAppState, ConsoleAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CONSOLE_SET_PREFIX:
      return {
        prefix: action.payload?.prefix || "",
      }
  }

  return state
}
